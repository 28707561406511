import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Text from "../components/text";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Introduction from "../components/introduction";

const Vase = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      vase1: file(name: { eq: "vase01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vase2: file(name: { eq: "vase02" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vase3: file(name: { eq: "vase03" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
	  vase4: file(name: { eq: "vase04" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
	  vase5: file(name: { eq: "vase05" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
	  vase6: file(name: { eq: "vase06" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <SEO title='Vase Collection "Geheimnis"' />
        <Title text='Vase Collection "Geheimnis"' />
        <Introduction>
          {intl.formatMessage({ id: "vase.intro_1" })}
        </Introduction>
        <Text>{intl.formatMessage({ id: "vase.text_1" })}</Text>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 20px;
            margin-top: 20px;
          `}
        >
          <Img
            fluid={data.vase1.childImageSharp.fluid}
            key={data.vase1.name}
            alt={data.vase1.name}
          />
          <Img
            fluid={data.vase2.childImageSharp.fluid}
            key={data.vase2.name}
            alt={data.vase2.name}
          />
          <Img
            fluid={data.vase3.childImageSharp.fluid}
            key={data.vase3.name}
            alt={data.vase3.name}
          />
          <Img
            fluid={data.vase4.childImageSharp.fluid}
            key={data.vase4.name}
            alt={data.vase4.name}
          />
          <Img
            fluid={data.vase5.childImageSharp.fluid}
            key={data.vase5.name}
            alt={data.vase5.name}
          />
          <Img
            fluid={data.vase6.childImageSharp.fluid}
            key={data.vase6.name}
            alt={data.vase6.name}
          />
        </div>
        <Return />
      </Layout>
    </>
  );
};

export default Vase;
